var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-card',[_c('b-table',{staticClass:"text-center p-0 ur-table",attrs:{"fields":[
        { key: 'asset_holder', label: 'cnpj' },
        { key: 'committed_amount', label: 'comprometido' },
        { key: 'uncommitted_amount', label: 'disponível' },
        { key: 'total_amount', label: 'total' },
        { key: 'expected_settlement_date', label: 'previsão da liquidação' } ],"items":_vm.urData,"responsive":"xl"},scopedSlots:_vm._u([{key:"cell(asset_holder)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("tax_id")(data.item.asset_holder))+" ")]}},{key:"cell(liquidated_amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.liquidated_amount))+" ")]}},{key:"cell(committed_amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(_vm.commitedAmount))+" ")]}},{key:"cell(uncommitted_amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.uncommitted_amount))+" ")]}},{key:"cell(total_amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.total_amount))+" ")]}},{key:"cell(expected_settlement_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.expected_settlement_date))+" ")]}}])})],1),_c('b-card',[_c('h3',{staticClass:"text-center"},[_vm._v(" Obrigações de Contrato ")]),_c('b-table',{staticClass:"text-center p-0 ur-table",attrs:{"responsive":"xl","items":_vm.urData[0].contract_obligations,"fields":[
        { key: 'expectedSettlementDate', label: 'previsão da liquidação' },
        { key: 'tradeRepository', label: 'adquirente' },
        { key: 'contract_holder', label: 'titular do contrato' },
        { key: 'beneficiary', label: 'beneficiário' },
        { key: 'effect_amount', label: 'valor efetivo' } ]},scopedSlots:_vm._u([{key:"cell(expectedSettlementDate)",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(_vm._f("date")(_vm.urData[0].expected_settlement_date))+" ")])]},proxy:true},{key:"cell(tradeRepository)",fn:function(data){return [_c('p',[_vm._v(" "+_vm._s(_vm._f("tax_id")(data.item.trade_repository))+" ")])]}},{key:"cell(contract_holder)",fn:function(data){return [_c('p',[_vm._v(" "+_vm._s(_vm._f("tax_id")(data.item.contract_holder))+" ")])]}},{key:"cell(effect_amount)",fn:function(data){return [_c('p',[_vm._v(" "+_vm._s(_vm._f("value")(data.item.effect_amount))+" ")])]}},{key:"cell(beneficiary)",fn:function(data){return [_c('p',[_vm._v(" "+_vm._s(_vm._f("tax_id")(data.item.bank_account.document_number))+" ")])]}}])})],1),_c('b-card',[_c('h3',{staticClass:"text-center mb-2"},[_vm._v(" Liquidação ")]),_c('b-table',{staticClass:"text-center ur-table",attrs:{"per-page":5,"fields":_vm.fieldsLiquidation,"items":_vm.urData[0].settlements,"current-page":_vm.currentPage,"responsive":""},scopedSlots:_vm._u([{key:"cell(settlementDate)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.settlement_date))+" ")]}},{key:"cell(documentNumber)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("tax_id")(data.item.asset_holder))+" ")]}},{key:"cell(amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.amount))+" ")]}}])}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.dataLiquidation.length,"per-page":5,"align":"center","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }