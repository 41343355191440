<template>
  <b-container fluid>
    <b-card>
      <b-table
        :fields="[
          { key: 'asset_holder', label: 'cnpj' },
          { key: 'committed_amount', label: 'comprometido' },
          { key: 'uncommitted_amount', label: 'disponível' },
          { key: 'total_amount', label: 'total' },
          { key: 'expected_settlement_date', label: 'previsão da liquidação' },
        ]"
        :items="urData"
        responsive="xl"
        class="text-center p-0 ur-table"
      >
        <template #cell(asset_holder)="data">
          {{ data.item.asset_holder | tax_id }}
        </template>

        <template #cell(liquidated_amount)="data">
          {{ data.item.liquidated_amount | value }}
        </template>

        <template #cell(committed_amount)="data">
          {{ commitedAmount | value }}
        </template>

        <template #cell(uncommitted_amount)="data">
          {{ data.item.uncommitted_amount | value }}
        </template>

        <template #cell(total_amount)="data">
          {{ data.item.total_amount | value }}
        </template>

        <template #cell(expected_settlement_date)="data">
          {{ data.item.expected_settlement_date | date }}
        </template>
      </b-table>
    </b-card>

    <b-card>
      <h3 class="text-center">
        Obrigações de Contrato
      </h3>

      <b-table
        responsive="xl"
        class="text-center p-0 ur-table"
        :items="urData[0].contract_obligations"
        :fields="[
          { key: 'expectedSettlementDate', label: 'previsão da liquidação' },
          { key: 'tradeRepository', label: 'adquirente' },
          { key: 'contract_holder', label: 'titular do contrato' },
          { key: 'beneficiary', label: 'beneficiário' },
          { key: 'effect_amount', label: 'valor efetivo' },
        ]"
      >

        <template #cell(expectedSettlementDate)>
          <p> {{ urData[0].expected_settlement_date | date }} </p>
        </template>

        <template #cell(tradeRepository)="data">
          <p> {{ data.item.trade_repository| tax_id }} </p>
        </template>

        <template #cell(contract_holder)="data">
          <p> {{ data.item.contract_holder | tax_id }} </p>
        </template>

        <template #cell(effect_amount)="data">
          <p> {{ data.item.effect_amount | value }} </p>
        </template>

        <template #cell(beneficiary)="data">
          <p> {{ data.item.bank_account.document_number | tax_id }} </p>
        </template>

      </b-table>
    </b-card>

    <b-card>
      <h3 class="text-center mb-2">
        Liquidação
      </h3>
      <b-table
        :per-page="5"
        :fields="fieldsLiquidation"
        :items="urData[0].settlements"
        :current-page="currentPage"
        class="text-center ur-table"
        responsive
      >
        <template #cell(settlementDate)="data">
          {{ data.item.settlement_date | date }}
        </template>

        <template #cell(documentNumber)="data">
          {{ data.item.asset_holder | tax_id }}
        </template>

        <template #cell(amount)="data">
          {{ data.item.amount | value }}
        </template>

      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="dataLiquidation.length"
        :per-page="5"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-card>

  </b-container>
</template>

<script>
import {
    BContainer, BCard, BTable, BPagination,
} from 'bootstrap-vue';

export default {
    components: {
        BContainer,
        BCard,
        BTable,
        BPagination,
    },
    data() {
        return ({
            currentPage: 1,
            dataLiquidation: [],
            fieldsLiquidation: [
                {
                    key: 'settlementDate', label: 'Data Liquidação',
                },
                {
                    key: 'documentNumber', label: 'Documento',
                },
                {
                    key: 'amount', label: 'valor',
                },
            ],
            urData: [{}],
        });
    },

    computed: {
        commitedAmount() {
            return this.urData[0].contract_obligations.reduce((acc, current) => acc + current.effect_amount, 0);
        },
    },

    async created() {
        const urData = this.$store.getters['ur/ur'];
        this.urData = [urData];
    },

    methods: {
        objectIsEmpty(obj) {
            for (const prop in obj) {
                if (obj.hasOwnProperty(prop)) return false;
            }
            return true;
        },
    },
};
</script>

  <style>

  .ur-table th, [dir] .ur-table td {
    padding: 0.72rem;
    border-top: 1px solid #ebe9f1;
    width: 200px;
  }
  </style>
